import React from 'react'
import { Modal } from 'react-bootstrap'
import { Job } from '../../models/shared/Job'
import { useForm } from 'react-hook-form'
import { doc, setDoc } from 'firebase/firestore'
import { useFirestore } from 'reactfire'
import { COLLECTIONS } from '../../utils/shared/constants'
import { formatDateTime } from '../../utils/shared/format'

interface Props {
    job: Job
    onClose: () => void
}

interface Inputs {
    time: string
}

function ScheduleChargingJobModal(props: Props) {
    const { job } = props
    const { register, handleSubmit } = useForm<Inputs>(
        {
            defaultValues: {
                time: formatDateTime(job.scheduledAt, { unix: true })
            }
        }
    )
    const firestore = useFirestore();

    const onSubmit = async (data: Inputs) => {
        const newScheduledAt = new Date(data.time);
        await setDoc(doc(firestore, `${COLLECTIONS.JOBS}/${job.id}`), { scheduledAt: newScheduledAt }, { merge: true });
        props.onClose();
    }


    return <Modal show={true} onHide={() => props.onClose()}>
        <Modal.Header closeButton>
            <Modal.Title>Schedule Charging Job</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Body>
                <p>Job: {props.job.action}</p>

                <input
                    type='datetime-local'
                    {...register('time', { valueAsDate: true })} />

            </Modal.Body>
            <Modal.Footer>
                <button type='submit' className='btn btn-primary'>Save</button>

            </Modal.Footer>
        </form>

    </Modal>
}

export default ScheduleChargingJobModal
