import { FC, useState } from 'react'
import { texts } from '../utils/texts'
import { Job, JobStatus } from '../models/shared/Job'
import { Dropdown, Table } from 'react-bootstrap';
import { ThreeDotsToggle } from './ThreeDotsToggle';
import { doc, setDoc } from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import { COLLECTIONS } from '../utils/shared/constants';
import ScheduleChargingJobModal from './modals/ScheduleChargingJobModal';

interface IProps {
    jobs: Job[]
}

/**
* @author
* @function @JobList
**/

export const JobList: FC<IProps> = (props) => {
    const nonCancelledJobs = props.jobs.filter(job => job.status !== 'cancelled');
    const firestore = useFirestore();
    const [jobToUpdate, setJobToUpdate] = useState<Job | null>(null);

    const handleJobCancel = async (jobId: string) => {
        await setDoc(doc(firestore, COLLECTIONS.JOBS, jobId), { status: 'cancelled' }, { merge: true });
    }

    return (
        <div className='my-5'>
            <h5>{texts.scheduledActions}</h5>
            <Table hover striped>
                <thead>
                    <tr>
                        <th>{texts.time}</th>
                        <th>{texts.action}</th>
                        <th>{texts.status}</th>
                        <th>Operation</th>
                    </tr>
                </thead>
                <tbody>
                    {nonCancelledJobs.map((job, index) => (
                        <tr key={index}>
                            <td>{job.scheduledAt.toLocaleString()}</td>
                            <td>{job.action}</td>
                            <td><JobStatusIcon status={job.status} /></td>
                            <td>
                                <Dropdown align={'end'}>
                                    <Dropdown.Toggle variant="secondary" id="dropdown-basic" as={ThreeDotsToggle} />

                                    <Dropdown.Menu >
                                        <Dropdown.Item onClick={() => setJobToUpdate(job)}><i className='bi bi-pencil' /> Update</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleJobCancel(job.id || '')}><i className='bi bi-trash' /> Delete</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            {jobToUpdate && <ScheduleChargingJobModal job={jobToUpdate} onClose={() => setJobToUpdate(null)} />}
        </div >
    )
}

interface JobStatusIconProps {
    status: JobStatus
}
function JobStatusIcon({ status }: JobStatusIconProps) {
    switch (status) {
        case 'scheduled':
            return <i className='bi bi-clock' />
        case 'completed':
            return <i className='bi bi-check-circle-fill text-success' />
        case 'failed':
            return <i className='bi bi-x-circle-fill text-danger' />
        default:
            return <i className='bi bi-question-circle' />
    }
}
